<template>
  <div class="box">
    <div class="itemText">
      <span v-if="tType == 'CH'" style="font-size: 14px">出发省份:</span>
      <span v-if="tType == 'EN'">Start provinces:</span>
      <el-select v-model="value" :placeholder="tType == 'CH'?'选择省份':'Select province'" @change="selectChange" class="select1" clearable>
        <el-option
            v-for="item in options"
            :key="item.label"
            :label="item.label"
            :value="item.label">
        </el-option>
      </el-select>
      <el-select v-model="value1" :placeholder="tType == 'CH'?'选择城市':'Select the city'" class="select2" clearable>
        <el-option
            v-for="item in options1"
            :key="item.label"
            :label="item.label"
            :value="item.label">
        </el-option>
      </el-select>
      <el-button class="saveBtn" @click="go" v-if="tType == 'CH'">确定</el-button>
      <el-button class="saveBtn" @click="go" v-if="tType == 'EN'">confirm</el-button>
    </div>
  </div>
</template>

<script>
import AllAddress from './../AddressData.js'
export default {
  name: "zijia",
  components:{},
  props:['info'],
  data(){
    return {
      tType:'CH',
      value:'',
      value1:'',
      options:[],
      options1:[],
      dataInfo:{}
    }
  },
  created() {
    this.tType = sessionStorage.getItem('textType')
  },
  mounted() {
    this.dataInfo = this.info
    // console.log(AllAddress)
    AllAddress.forEach((item,index)=>{
      this.options.push({label:item.name,value:item.name})
    })
  },
  methods:{
    selectChange(e){
      this.options1 = []
      console.log(e)
      AllAddress.forEach((item,index)=>{
        if(e == item.name){
          item.children.forEach((items,index)=>{
            this.options1.push({label:items.name,value:items.name,id:items.id})
          })

        }
      })
      this.value1 = this.options1[0].value
    },
    go(){
      var start = ''
      this.options1.forEach((item,index)=>{
        if(this.value1 == item.value){
          start = item.id
        }
      })
      window.open(`https://ditu.amap.com/dir?from[adcode]=${start}` +
          `&from[name]=${this.value}${this.value1}` +
          `&from[id]=${start}1652312148170` +
          '&from[poitype]=' +
          '&from[lnglat]=120.21079199999997,30.246026' +
          '&from[modxy]=' +
          '&to[adcode]=320213' +
          '&to[name]=古运河景区' +
          '&to[id]=B0207005CA' +
          '&to[poitype]=110202' +
          '&to[lnglat]=120.318587,31.557315' +
          '&to[modxy]=119.442678,31.316604' +
          '&type=car' +
          '&policy=1')

    }
  }
}
</script>

<style scoped lang="less">
.box{
  width: 100%;
  height: 327px;
  display: flex;
  margin-bottom: 120px;
  align-items: center;
  background: #FFFFFF;
}
.itemText{
  font-size: 20px;
  font-weight: 400;
  color: #333333;
  line-height: 28px;
  text-align: center;
}
.saveBtn{
  width: 120px;
  height: 40px;
  margin-left: 30px;
  background: #007B8A;
  color: #FFFFFF;
}
.select1{
  margin-left: 20px;
}
.select2{
  margin-left: 30px;
}
</style>
